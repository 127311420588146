*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.main-page{
    width: 100vw;
    height: 100vh;
    background-color: #07bfa5;

    .input-content{
        width: 100%;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .input{
        width: 100%;
        height: 100%;
        background-color: white;
        padding: 30px;
        border-radius: 10px;
        .input-text{
            width: 100%;
            padding: 15px;
            margin: 10px 0px;
            border :1px solid #07bfa5;
            border-radius: 10px; 
            outline: none;
        }
        .send-message{
            width: 100%;
            padding: 15px;
            margin: 10px 0px;
            border :1px solid #07bfa5;
            border-radius: 10px; 
            outline: none;
            background-color: #07bfa5;
            color: white;
        }
    }

}